import React, { Component, createRef } from "react";
import {
  HttpRequest,
  HttpRequestTypes,
  UploadImage,
} from "../../config/GeneralMethods";

import "../../assets/styles/documents.css";
import DocumentHeader from "../../components/DocumentHeader";
import DocumentMenu from "../../components/DocumentMenu";
import { pdfjs } from "react-pdf";
import { ENV } from "../../config";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { readAsDataURL, readAsImage } from "../../assets/utils/asyncReader";
import { ggID } from "../../assets/utils/helper.js";
import { fetchFont, getAsset } from "../../assets/utils/prepareAssets.js";
import "../../assets/styles/main.css";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import PSPDFKit from "../../screens/pspdfkit";
import BLANK_PDF_URL from "../../assets/files/blank.pdf";
import AddSignatureModal from "../../components/Modals/AddSignatureModal";
import AddTextModal from "../../components/Modals/AddTextModal";
import FillPDFModal from "../../components/Modals/FillPDFModal";
import {
  ALERT_MODAL_TYPES,
  APP_OPTIONS,
  Colors,
  FILE_STATUS,
  SIGNATURE_STATUS,
  TEMPLATE_FIELDS_TYPES,
} from "../../constants";
import LoadingModal from "../../components/Modals/LoadingModal";
import {
  CONST_TEXT_FIELDS,
  EXTERNAL_ORGS_SIGNATURE_FIELDS,
  EXTERNAL_APPROVALS_SIGNATURE_FIELDS,
  FREELANCERS_FIELDS,
  FREELANCERS_FIELDS_TITLES,
  FREELANCER_AND_ORG_FIELDS,
  ORGANIZATIONS_FIELDS,
  ORGANIZATIONS_SIGNATURE_FIELDS,
  SIGNATURE_FIELDS,
} from "../../constants/documentFields";
import moment from "moment";
import FillPDFFReelancerModal from "../../components/Modals/FillPDFFReelancerModal";
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const genID = ggID();
const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;

const FREELANCERS_SAVE_STATUS = {
  SAVE: 1,
  SEND: 2,
  SIGNED: 3,
};

const USER_TYPES = {
  FREELANCER: 1,
  ORG: 2,
  EXT_ORG: 3,
  EXT_APPROVAL: 4,
};

// const CONST_TEXT_FIELDS = ["שם פרטי", "שם משפחה", "דוא\"ל", "מס׳ טלפון", "גוש", "חלקה", "מגרש",
//   "כתובת", "עיר", "מספר היתר", "תאריך היתר", "תיאור הבקשה", "מס׳ בקשה", "מס׳ תיק בניין", "שם מלא"];

class Documents extends Component {
  constructor(props) {
    super(props);

    this.pageRef = createRef();
    this.child = createRef();
    this.freelancerModalRef = createRef();
    this.childtoggle = createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangesignature = this.handleChangesignature.bind(this);
    this.bindhandleChangesignature = this.bindhandleChangesignature.bind(this);
    this.bindhandleChange = this.bindhandleChange.bind(this);
  }

  state = {
    isLoggedIn: false,
    pdfUrl: BLANK_PDF_URL,
    canMountPSPDFKIT: false,
    userInfoObj: null,

    fileName: "",
    errorMessage: "",
    title: "",
    pageNumber: 1,
    numPages: 1,
    isDocumentLoading: false,
    images: [],
    texts: [],
    editTexts: [],
    currTextValue: "",
    currTextIndex: -1,
    isEditModalVisible: false,
    isEditModalVisibleFreelancer: false,
    isEditModalVisiblePSPDFKIT: false,
    isEditModalVisiblePSPDFKITsigntaure: false,
    isSavingPDF: false,
    isSavingFinished: false,
    savingResult: "",
    savingResultSuccess: true,
    selectedPageIndex: 0,
    allObjects: [],
    currentFont: "assistant",
    addingDrawing: false,
    owId: "",
    link: "",
    docId: "",
    tokenId: "",
    currWeight: "100",
    color: "#000",
    documentTitle: "",
    isPDFNotFoundInServer: false,
    tryCount: 0,
    selectedObject: {},
    notesVisibleArray: [],
    firstName: "",
    lastName: "",
    labelPSPDFKIT: "",
    labelPSPDFKITsignature: "",
    fieldNamePSPDFKIT: "",
    fieldNamePSPDFKITsignature: "",
    dynamicSelect: "",
    binddynamicSelect: "",
    dynamicSelectsignautre: "",
    binddynamicSelectsignautre: "",
    options: [],
    optionsSignature: [],
    docToken: null,
    categories: [],
    selectedCategory: "",
    modalErrorMessage: "",
    selectedOrganizationType: "",
    isSaveAndExitCustomVisible: false,
    isPopupShouldToggle: false,
    freelancerToggleChecked: false,
    organizationToggledChecked: false,
    freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SAVE,
    freelancerModalErrorMessage: "",
    declineReasonErrorMessage: "",
    currentDocumentFreelancerCategories: [],
    organizationCategories: [],
    isApproveButtonHidden: false,
    externalOrgToggledChecked: false,
    selectedExternalOrg: "",
    externalApprovalToggledChecked: false,
    selectedExternalApproval: "",
    certificateCategory: "",
    file: null,
  };

  componentDidMount() {
    this.loadScreenComponents();
  }

  getIsEsignUser = (userInfoObj = null) => {
    if (userInfoObj === null) {
      userInfoObj = this.state.userInfoObj;
    }

    if (!userInfoObj) return false;

    let { tmc = false } = userInfoObj;

    if (userInfoObj.app === APP_OPTIONS.ESIGN && !tmc) return true;
    return false;
  };

  loadFreelancersList = async () => {
    try {
      //let url = `${ENV.API_URL}/api/category/get-all-freelancers-titles`; //GET Freelancers Only
      let url = `${ENV.API_URL}/api/category/get-all`;
      let res = await HttpRequest(url, null, HttpRequestTypes.GET, null);

      if (res && res.success) {
        let { categories } = res;

        let categoriesTitles = categories.map((val) => val.name);
        this.setState({ categories: categoriesTitles });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getIsFreelancer = (userInfoObj = null) => {
    if (userInfoObj === null) {
      userInfoObj = this.state.userInfoObj;
    }

    if (!userInfoObj) return false;

    let { app, yzm = false, freelancer = null } = userInfoObj;
    return app === APP_OPTIONS.TOFES4 && yzm === true && freelancer !== null;
  };

  getIsOrg = (userInfoObj = null) => {
    if (userInfoObj === null) {
      userInfoObj = this.state.userInfoObj;
    }

    if (!userInfoObj) return false;

    return userInfoObj.isOrg || false;
  };

  getIsExternalOrg = (userInfoObj = null) => {
    if (userInfoObj === null) {
      userInfoObj = this.state.userInfoObj;
    }

    if (!userInfoObj) return false;

    return userInfoObj.isExternalOrg || false;
  };

  getIsExternalApproval = (userInfoObj = null) => {
    if (userInfoObj === null) {
      userInfoObj = this.state.userInfoObj;
    }

    if (!userInfoObj) return false;

    return userInfoObj.isExternalApproval || false;
  };

  getIsAdminWithTemplateCreation = (userInfoObj = null) => {
    if (userInfoObj === null) {
      userInfoObj = this.state.userInfoObj;
    }

    if (!userInfoObj) return false;

    let { tmc = false } = userInfoObj;
    return tmc;
  };

  loadScreenComponents = async () => {
    let querySearch = new URLSearchParams(window.location.search);
    let t = querySearch.get("t");

    //decode the JWT Token
    let userInfoObj = await this.loadUserInfoAfterDecodeJWTToken(t);
    this.setState({ docToken: t, userInfoObj });

    if (userInfoObj && userInfoObj?.isNonFieldFreelancer === true) {
      
    }

    console.log("userInfoObj: ", userInfoObj);

    let options = [],
      optionsSignature = [];

    let pdfUrl = BLANK_PDF_URL;
    let isLoggedIn = false;
    let link = "";

    if (userInfoObj) {
      //here we should control the signature & text constant field by user mode
      let {
        tmc = false,
        yzm = false,
        freelancer = null,
        document,
        project = {},
        isOrg = false,
        organizationType = "",
        isExternalOrg = false,
        category = "",
        isExternalApproval = false,
      } = userInfoObj;

      if (this.getIsAdminWithTemplateCreation(userInfoObj)) {
        this.childtoggle.current.handleChange(true);

        this.loadFreelancersList();
        //Render all Admin Fields
        CONST_TEXT_FIELDS.map((val) =>
          options.push(this.buildOptionItem(val, val, "", [], false, true))
        );
        SIGNATURE_FIELDS.map((val) =>
          optionsSignature.push(
            this.buildSignatureOptionItem(
              val,
              val,
              [],
              false,
              true,
              false,
              [],
              []
            )
          )
        );
      } else if (isOrg) {
        //Organization User
        let template = [];

        this.checkAndUpdateApproveBtnToAlreadySigned(
          document,
          USER_TYPES.ORG,
          organizationType
        );

        if (document && document.template && document.template.length > 0) {
          template = document.template;
        }

        template = template
          .filter((item) => item.label.lastIndexOf("+") !== -1)
          .filter((item) => {
            return (
              item.label.substring(item.label.lastIndexOf("+") + 1) ===
              organizationType
            );
          });

        template
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.TEXT)
          .map((val) => {
            let optionSameLabelIndex = options.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (optionSameLabelIndex === -1) {
              //let isHidden = options.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              options.push(
                this.buildOptionItem(
                  val.label.substr(0, val.label.lastIndexOf("+")),
                  val.label,
                  "",
                  [val.formFieldName],
                  true,
                  true,
                  isHidden
                )
              );
            } else {
              options[optionSameLabelIndex].names.push(val.formFieldName);
            }

            return null;
          });

        template
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.SIGNATURE)
          .filter((val) => {
            let labelWithoutDeparture = val.label.substr(
              0,
              val.label.indexOf("+")
            );
            return !ORGANIZATIONS_SIGNATURE_FIELDS.includes(
              labelWithoutDeparture
            );
          })
          .map((val) => {
            let signatureOptionSameLabelIndex = optionsSignature.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (signatureOptionSameLabelIndex === -1) {
              //let isHidden = optionsSignature.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              optionsSignature.push(
                this.buildSignatureOptionItem(
                  val.label.substr(0, val.label.lastIndexOf("+")),
                  val.label,
                  [val.formFieldName],
                  true,
                  true,
                  val.isFilled || false,
                  [],
                  [],
                  isHidden
                )
              );
            } else {
              optionsSignature[signatureOptionSameLabelIndex].names.push(
                val.formFieldName
              );
            }

            return null;
          });

        if (document.fileStatus === FILE_STATUS.APPROVED) {
          this.setState({
            freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SIGNED,
          });
        }

        this.setState({ options, optionsSignature });
      } else if (isExternalOrg) {
        let template = [];

        this.checkAndUpdateApproveBtnToAlreadySigned(
          document,
          USER_TYPES.EXT_ORG,
          category
        );

        if (document && document.template && document.template.length > 0) {
          template = document.template;
        }

        template = template
          .filter((item) => item.label.lastIndexOf("@") !== -1)
          .filter((item) => {
            return (
              item.label.substring(item.label.lastIndexOf("@") + 1) === category
            );
          });

        template
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.TEXT)
          .map((val) => {
            let optionSameLabelIndex = options.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (optionSameLabelIndex === -1) {
              //let isHidden = options.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              options.push(
                this.buildOptionItem(
                  val.label.substr(0, val.label.lastIndexOf("@")),
                  val.label,
                  "",
                  [val.formFieldName],
                  true,
                  true,
                  isHidden
                )
              );
            } else {
              options[optionSameLabelIndex].names.push(val.formFieldName);
            }

            return null;
          });

        template
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.SIGNATURE)
          .filter((val) => {
            return val.label.substr(0, val.label.indexOf("@")) !== -1;
          })
          .map((val) => {
            let signatureOptionSameLabelIndex = optionsSignature.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (signatureOptionSameLabelIndex === -1) {
              //let isHidden = optionsSignature.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              let cc = this.buildSignatureOptionItem(
                val.label.substr(0, val.label.lastIndexOf("@")),
                val.label,
                [val.formFieldName],
                true,
                true,
                val.isFilled || false,
                [],
                [],
                isHidden
              );
              optionsSignature.push(cc);
            } else {
              optionsSignature[signatureOptionSameLabelIndex].names.push(
                val.formFieldName
              );
            }

            return null;
          });

        if (document.fileStatus === FILE_STATUS.APPROVED) {
          this.setState({
            freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SIGNED,
          });
        }

        this.setState({ options, optionsSignature });
      } else if (isExternalApproval) {
        let template = [];

        this.checkAndUpdateApproveBtnToAlreadySigned(
          document,
          USER_TYPES.EXT_APPROVAL,
          category
        );

        if (document && document.template && document.template.length > 0) {
          template = document.template;
        }

        template = template
          .filter((item) => item.label.lastIndexOf("<") !== -1)
          .filter((item) => {
            return (
              item.label.substring(item.label.lastIndexOf("<") + 1) === category
            );
          });

        template
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.TEXT)
          .map((val) => {
            let optionSameLabelIndex = options.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (optionSameLabelIndex === -1) {
              //let isHidden = options.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              options.push(
                this.buildOptionItem(
                  val.label.substr(0, val.label.lastIndexOf("<")),
                  val.label,
                  "",
                  [val.formFieldName],
                  true,
                  true,
                  isHidden
                )
              );
            } else {
              options[optionSameLabelIndex].names.push(val.formFieldName);
            }

            return null;
          });

        template
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.SIGNATURE)
          .filter((val) => {
            return val.label.substr(0, val.label.indexOf("<")) !== -1;
          })
          .map((val) => {
            let signatureOptionSameLabelIndex = optionsSignature.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (signatureOptionSameLabelIndex === -1) {
              //let isHidden = optionsSignature.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              let cc = this.buildSignatureOptionItem(
                val.label.substr(0, val.label.lastIndexOf("<")),
                val.label,
                [val.formFieldName],
                true,
                true,
                val.isFilled || false,
                [],
                [],
                isHidden
              );
              optionsSignature.push(cc);
            } else {
              optionsSignature[signatureOptionSameLabelIndex].names.push(
                val.formFieldName
              );
            }

            return null;
          });

        if (document.fileStatus === FILE_STATUS.APPROVED) {
          this.setState({
            freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SIGNED,
          });
        }

        //this.setState({ options, optionsSignature });
      } else if (this.getIsFreelancer(userInfoObj)) {
        //Render All Freelancer Fields

        //If he is Freelancer then:
        //1. Get Freelancer fields from Template
        let template = [];
        let certificateCategory = "";

        this.checkAndUpdateApproveBtnToAlreadySigned(
          document,
          USER_TYPES.FREELANCER,
          category
        );

        if (document && document.template && document.template.length > 0) {
          template = document.template;
        }

        let categories = [];
        if (userInfoObj.category) {
          categories = [userInfoObj.category];
        } else if (project && project.freelancers) {
          project.freelancers
            .filter(
              (item) =>
                item.email.toLowerCase() === freelancer.email.toLowerCase()
            )
            .map((item) => {
              item.category.map((c) => {
                if (!categories.includes(c)) {
                  categories.push(c);
                }

                return null;
              });

              return null;
            });
        }

        let freelancerFields = template.filter((val) => {
          let lastIndexOfUnderscore = val.label.lastIndexOf("_");

          return (
            lastIndexOfUnderscore !== -1 &&
            categories.includes(val.label.substr(lastIndexOfUnderscore + 1))
          );
        });

        freelancerFields
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.TEXT)
          .map((val) => {
            let optionSameLabelIndex = options.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (optionSameLabelIndex === -1) {
              //let isHidden = options.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              options.push(
                this.buildOptionItem(
                  val.label.substr(0, val.label.lastIndexOf("_")),
                  val.label,
                  "",
                  [val.formFieldName],
                  true,
                  true,
                  isHidden
                )
              );
            } else {
              options[optionSameLabelIndex].names.push(val.formFieldName);
            }

            return null;
          });

        freelancerFields
          .filter((val) => val.type === TEMPLATE_FIELDS_TYPES.SIGNATURE)
          .map((val) => {
            let signatureOptionSameLabelIndex = optionsSignature.findIndex(
              (item) => item.originalLabel === val.label
            );

            if (signatureOptionSameLabelIndex === -1) {
              //let isHidden = optionsSignature.filter(itm => itm.label === val.label.substr(0, val.label.lastIndexOf("_"))).length > 0;
              let isHidden = false;
              optionsSignature.push(
                this.buildSignatureOptionItem(
                  val.label.substr(0, val.label.lastIndexOf("_")),
                  val.label,
                  [val.formFieldName],
                  true,
                  true,
                  val.isFilled || false,
                  [],
                  [],
                  isHidden
                )
              );
            } else {
              optionsSignature[signatureOptionSameLabelIndex].names.push(
                val.formFieldName
              );
            }

            return null;
          });

        let documentCertificates = document.certificates || [];
        let projectCertificates = project.certificates || [];

        if (
          documentCertificates.length > 0 &&
          documentCertificates.includes(category)
        ) {
          let projectCertificate = projectCertificates.find(
            (item) => item.category === category
          );

          if (!projectCertificate) {
            certificateCategory = category;
          }
        }

        this.setState({ certificateCategory });
      }

      this.setState({ options, optionsSignature });

      if (userInfoObj.document && userInfoObj.document.url) {
        pdfUrl = userInfoObj.document.url;

        let isNeedToPopulateFieldIfNOTExist =
          tmc || (freelancer === null && yzm === false);
        let template = userInfoObj.document.template || [];

        console.log("template: ", template);

        if (!isOrg && !isExternalOrg && !isExternalApproval) {
          template.map((val) => {
            if (val.type === TEMPLATE_FIELDS_TYPES.TEXT) {
              let ind = options.findIndex((item) => item.label === val.label);
              if (ind !== -1) {
                options[ind].value = val.value;
                options[ind].isPresent = true;

                if (!options[ind].names.includes(val.formFieldName)) {
                  options[ind].names.push(val.formFieldName);
                }
              } else if (isNeedToPopulateFieldIfNOTExist) {
                options.push(
                  this.buildOptionItem(
                    val.label,
                    val.label,
                    val.value,
                    [val.formFieldName],
                    true,
                    true
                  )
                );
              }
            } else if (val.type === TEMPLATE_FIELDS_TYPES.SIGNATURE) {
              let ind = optionsSignature.findIndex(
                (item) => item.label === val.label
              );
              if (ind !== -1) {
                optionsSignature[ind].isPresent = true;
                optionsSignature[ind].isFilled = true;

                if (!optionsSignature[ind].names.includes(val.formFieldName)) {
                  optionsSignature[ind].names.push(val.formFieldName);
                }
              } else if (isNeedToPopulateFieldIfNOTExist) {
                optionsSignature.push(
                  this.buildSignatureOptionItem(
                    val.label,
                    val.label,
                    [val.formFieldName],
                    true,
                    true,
                    val.isFilled || false,
                    [],
                    []
                  )
                );
              }
            }

            return null;
          });

          this.setState({ optionsSignature, options });
        }
      }

      if (userInfoObj.user) {
        isLoggedIn = true;
      }

      link = `${baseUrl}?t=${await this.makeTokenFromJsonObject(userInfoObj)}`;
    }

    this.setState({
      pdfUrl,
      isLoggedIn,
      userInfoObj,
      canMountPSPDFKIT: true,
      link,
      currentDocumentFreelancerCategories:
        this.getFreelancerCategoriesInThisProject(),
      organizationCategories: this.getOrganizationCategoriesForThisProject(),
    });
  };

  checkAndUpdateApproveBtnToAlreadySigned = (document, userType, category) => {
    if (!document) return;

    let isAlreadySigned = this.getIsDocumentAlreadySignedOrApproved(
      document,
      userType
    );

    if (!isAlreadySigned) {
      if (userType === USER_TYPES.FREELANCER) {
        let signedBy = document.signedBy || [];
        let currentUserIsSigned = signedBy.find(
          (usr) =>
            usr.signedCategories && usr.signedCategories.includes(category)
        );

        if (currentUserIsSigned) {
          isAlreadySigned = true;
        }
      } else if (userType === USER_TYPES.ORG) {
        let organizationSignatures = document.organizationSignatures || [];
        let currentUserIsSigned = organizationSignatures.find(
          (org) => org.organizationType === category
        );

        if (currentUserIsSigned) {
          isAlreadySigned = true;
        }
      } else if (userType === USER_TYPES.EXT_ORG) {
        let externalOrgsSigned = document.externalOrgsSigned || [];
        let currentUserIsSigned = externalOrgsSigned.find(
          (ext) => ext.category === category
        );

        if (currentUserIsSigned) {
          isAlreadySigned = true;
        }
      } else if (userType === USER_TYPES.EXT_APPROVAL) {
        let externalApprovalsSigned = document.externalApprovalsSigned || [];
        let currentUserIsSigned = externalApprovalsSigned.find(
          (ext) => ext.category === category
        );

        if (currentUserIsSigned) {
          isAlreadySigned = true;
        }
      }
    }

    if (isAlreadySigned) {
      this.setState({
        freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SIGNED,
      });
    }
  };

  getIsDocumentAlreadySignedOrApproved = (document, userType) => {
    let isStatusIsSigned = false;

    try {
      if (
        userType === USER_TYPES.FREELANCER &&
        document.signatureStatus === SIGNATURE_STATUS.SIGNED
      ) {
        isStatusIsSigned = true;
      } else if (
        (userType === USER_TYPES.ORG || userType === USER_TYPES.EXT_ORG) &&
        document.fileStatus === FILE_STATUS.APPROVED
      ) {
        isStatusIsSigned = true;
      }
    } catch (e) {
      console.log(e);
    }

    return isStatusIsSigned;
  };

  buildOptionItem = (
    label,
    originalLabel,
    value,
    names,
    isPresent,
    isVisibleInDDL,
    isHidden = false
  ) => {
    return {
      label,
      originalLabel,
      value,
      names,
      isPresent,
      isVisibleInDDL,
      isHidden,
    };
  };

  buildSignatureOptionItem = (
    label,
    originalLabel,
    names,
    isPresent,
    isVisibleInDDL,
    isFilled,
    items = [],
    relatedIds = [],
    isHidden = false
  ) => {
    return {
      label,
      originalLabel,
      names,
      isPresent,
      isVisibleInDDL,
      isFilled,
      items,
      relatedIds,
      isHidden,
    };
  };

  getIsAllFreelancersFieldsFilled = () => {
    try {
      let { userInfoObj } = this.state;

      if (
        userInfoObj &&
        userInfoObj.document &&
        userInfoObj.document.template
      ) {
        let { template } = userInfoObj.document;

        let freelancerFields = template.filter((val) => {
          let lastIndexOfUnderscore = val.label.lastIndexOf("_");
          return lastIndexOfUnderscore !== -1;
        });

        for (let i = 0; i < freelancerFields.length; i++) {
          let item = freelancerFields[i];

          if (
            item.type === TEMPLATE_FIELDS_TYPES.SIGNATURE &&
            item.isFilled === false
          ) {
            return false;
          } else if (
            item.type === TEMPLATE_FIELDS_TYPES.TEXT &&
            item.value !== null &&
            item.value.trim().length === 0
          ) {
            return false;
          }
        }

        return true;
      }
    } catch (e) {
      console.log(e);
    }

    return false;
  };

  getFreelancerListInThisDocument = () => {
    let freelancers = [];

    try {
      let { userInfoObj } = this.state;

      if (
        userInfoObj &&
        userInfoObj.document &&
        userInfoObj.document.template
      ) {
        let { template } = userInfoObj.document;

        let freelancerFields = template.filter((val) => {
          let lastIndexOfUnderscore = val.label.lastIndexOf("_");
          return lastIndexOfUnderscore !== -1;
        });

        freelancerFields.forEach((val) => {
          let freelancer = val.label.substring(val.label.lastIndexOf("_") + 1);

          if (!freelancers.includes(freelancer)) {
            freelancers.push(freelancer);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }

    return freelancers;
  };

  getFreelancerCategoriesByProject = (email) => {
    let projectFreelancers = [];

    try {
      let { userInfoObj } = this.state;

      if (!userInfoObj) return [];
      if (!userInfoObj.project) return [];

      let projectFreelancerList = userInfoObj.project.freelancers;
      projectFreelancerList
        .filter((item) => item.email.toLowerCase() === email.toLowerCase())
        .forEach((item) => {
          item.category.forEach((cat) => {
            if (!projectFreelancers.includes(cat)) {
              projectFreelancers.push(cat);
            }
          });
        });
    } catch (e) {
      console.log(e);
    }

    return projectFreelancers;
  };

  getFreelancerCategoriesInThisProject = () => {
    let freelancers = [];

    try {
      let { userInfoObj } = this.state;

      if (!userInfoObj) return [];
      if (userInfoObj.freelancer === null) return [];

      if (userInfoObj.category) {
        freelancers = [userInfoObj.category];
      } else {
        let freelancer = userInfoObj.freelancer;
        let documentFreelancers = this.getFreelancerListInThisDocument();
        let projectCateory = this.getFreelancerCategoriesByProject(
          freelancer.email
        );

        projectCateory.forEach((item) => {
          if (
            documentFreelancers.includes(item) &&
            !freelancers.includes(item)
          ) {
            freelancers.push(item);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }

    return freelancers;
  };

  getOrganizationCategoriesForThisProject = () => {
    let orgs = [];

    try {
      let { userInfoObj } = this.state;
      if (!userInfoObj || !userInfoObj.isOrg) return [];

      let template = userInfoObj.document.template || [];
      template
        .filter((item) => item.label.lastIndexOf("+") !== -1)
        .map((item) => {
          let cat = item.label.substring(item.label.lastIndexOf("+") + 1);

          if (!orgs.includes(cat)) {
            orgs.push(cat);
          }

          return null;
        });
    } catch (e) {
      console.log(e);
    }

    return orgs;
  };

  focusOnField = (formFieldName) => {
    try {
      this.child.current._instance.contentDocument
        .querySelector(`.PSPDFKit-Annotation-Widget[name='${formFieldName}']`)
        .focus();
    } catch (e) {
      console.error(e);
    }
  };

  makeTokenFromJsonObject = async (userInfoObj) => {
    try {
      let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/makeTokenFromJson`;

      let params = {
        app: userInfoObj.app,
        userId: null,
        docId: userInfoObj.docId,
        tokenId: userInfoObj.tokenId,
      };

      let res = await HttpRequest(url, params, HttpRequestTypes.POST, null);

      if (res) return res.token;

      return "";
    } catch (e) {
      console.error(e);
      return "";
    }
  };

  loadUserInfoAfterDecodeJWTToken = async (token) => {
    try {
      if (!token) return null;

      let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/validateTokenInfo/${token}`;
      return await HttpRequest(url, null, HttpRequestTypes.GET, null);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  //TODO: Remove Me
  loadPDFFromDB = async () => {
    let { docId, tokenId } = this.state;

    if (!docId || docId.length === 0 || !tokenId || tokenId.length === 0) {
      this.setState({ isPDFNotFoundInServer: true, isDocumentLoading: false });
      return;
    }
    //Get File By ID
    let url = `${ENV.API_URL}/${ENV.DOCUMENTS_API_URL}/${docId}/${tokenId}`;

    let res = await HttpRequest(url, null, HttpRequestTypes.GET, null);

    if (res && res.success && res.data && res.data.length > 0) {
      let pdfUrl = res.data;

      if (pdfUrl && pdfUrl.length > 0) {
        let index = pdfUrl.lastIndexOf("/");
        let name = pdfUrl.substr(index);
        url = ENV.API_URL + "/" + ENV.UPLOADS_API_URL + "/" + name;
        res = await HttpRequest(url, null, HttpRequestTypes.GET, null);

        if (res && res.success) {
          let uploadUrl = res.url;

          if (!uploadUrl) {
            uploadUrl = "";
          }

          uploadUrl = uploadUrl
            .replace(".", ENV.API_URL)
            .replace("/public", "");

          if (uploadUrl.length > 0) {
            //this.setState({ pdfUrl: uploadUrl });

            setTimeout(() => {
              fetch(uploadUrl)
                .then((res) => res.blob())
                .then((blob) => {
                  blob.lastModified = new Date();
                  blob.name = "name_" + new Date().getTime() + "_.pdf";
                  this.addPDF(blob);
                })
                .catch((e) => {
                  console.error(e);
                });
            }, 1000);
          } else {
            this.setState({ isDocumentLoading: false });
          }
        } else {
          this.setState({ isDocumentLoading: false });
        }
      }
    } else {
      this.setState({ isPDFNotFoundInServer: true, isDocumentLoading: false });
    }
  };

  donwloadFile = async (pdfRes) => {
    const download = await getAsset("download");
    let { pdfBytes, name, type } = pdfRes;
    download(pdfBytes, name, type);
  };

  addConstant = (field) => {
    let { user } = this.state.userInfoObj;
    let { userSettings } = user || {};

    //let fieldValue = userSettings[field] || "";

    if (field === "firstName") {
      //this.setDynamicFields(field, fieldValue);
      this.child.current.firstNameTextBox();
    }
    if (field === "lastName") {
      //this.setDynamicFields(field, fieldValue);
      this.child.current.lastNameTextBox();
    }
    if (field === "address") {
      //this.setDynamicFields(field, fieldValue);
      this.child.current.addressTextBox();
    }

    this.onAddTextField(userSettings[field]);
  };

  setDynamicFields(field, fieldValue) {
    let { options } = this.state;

    let canPush = true;

    for (let i = 0; i < options.length; i++) {
      if (options[i].label === field) {
        canPush = false;
        break;
      }
    }

    if (canPush) {
      this.setState({
        options: [
          ...options,
          this.buildOptionItem(field, field, fieldValue, [], true, true),
        ],
      });
    }
  }

  signatureImage = () => {
    //let {images} = this.state;
    let { user } = this.state.userInfoObj;
    let { userSettings } = user || {};

    if (
      !userSettings ||
      !userSettings.signatureUrl ||
      userSettings.signatureUrl.length === 0
    ) {
      this.showNotificationAlert(
        ALERT_MODAL_TYPES.ERROR,
        "מילוי שדות",
        "שדה זה אינו מוגדר, ניתן להגדיר את זה בדף ההגדרות"
      );
      return;
    }

    this.readImageFromUrlAndCreateFile(userSettings.signatureUrl);
  };

  stampImage = () => {
    let { user } = this.state.userInfoObj;
    let { userSettings } = user || {};

    if (
      !userSettings ||
      !userSettings.stampUrl ||
      userSettings.stampUrl.length === 0
    ) {
      this.showNotificationAlert(
        ALERT_MODAL_TYPES.ERROR,
        "מילוי שדות",
        "שדה זה אינו מוגדר, ניתן להגדיר את זה בדף ההגדרות"
      );
      return;
    }

    this.readImageFromUrlAndCreateFile(userSettings.stampUrl);
  };

  addText = () => {
    if (this.childtoggle.current.state.checked) {
      this.setState({ isEditModalVisiblePSPDFKIT: true });
    } else {
      this.child.current.exmptyTextBox(
        this.state.fieldNamePSPDFKIT,
        this.state.labelPSPDFKIT,
        this.childtoggle.current.state.checked
      );
    }
  };

  addSignature = () => {
    if (this.childtoggle.current.state.checked) {
      this.setState({ isEditModalVisiblePSPDFKITsigntaure: true });
    } else {
      this.child.current.signatureTextBox(
        this.state.labelPSPDFKITsignature,
        this.childtoggle.current.state.checked
      );
    }
  };

  closeEditTextModalPSPDFKITsignature() {
    let {
      labelPSPDFKITsignature,
      selectedCategory,
      optionsSignature,
      freelancerToggleChecked,
      organizationToggledChecked,
      selectedOrganizationType,
      externalOrgToggledChecked,
      selectedExternalOrg,
      externalApprovalToggledChecked,
      selectedExternalApproval,
    } = this.state;
    this.setState({ modalErrorMessage: "" });

    let isFreelancer = FREELANCERS_FIELDS.includes(labelPSPDFKITsignature);

    if (
      labelPSPDFKITsignature === "" ||
      labelPSPDFKITsignature.trim().length === 0 ||
      (isFreelancer && selectedCategory.length === 0) ||
      (freelancerToggleChecked && selectedCategory.length === 0) ||
      (organizationToggledChecked && selectedOrganizationType.length === 0) ||
      (externalOrgToggledChecked && selectedExternalOrg.length === 0) ||
      (externalApprovalToggledChecked && selectedExternalApproval.length === 0)
    ) {
      this.setState({ modalErrorMessage: "יש למלא את כל השדות" });
      this.showNotificationAlert(
        ALERT_MODAL_TYPES.ERROR,
        "מילוי שדות",
        "יש למלא את כל השדות"
      );
      return;
    }

    let canPush = true;

    if (isFreelancer || freelancerToggleChecked) {
      labelPSPDFKITsignature = labelPSPDFKITsignature + "_" + selectedCategory;
    }

    if (organizationToggledChecked) {
      labelPSPDFKITsignature =
        labelPSPDFKITsignature + "+" + selectedOrganizationType;
    }

    if (externalOrgToggledChecked) {
      labelPSPDFKITsignature =
        labelPSPDFKITsignature + "@" + selectedExternalOrg;
    }

    if (externalApprovalToggledChecked) {
      labelPSPDFKITsignature =
        labelPSPDFKITsignature + "<" + selectedExternalApproval;
    }

    for (let i = 0; i < optionsSignature.length; i++) {
      if (optionsSignature[i].label === labelPSPDFKITsignature) {
        canPush = false;
        break;
      }
    }

    if (canPush) {
      this.setState({
        optionsSignature: [
          ...optionsSignature,
          this.buildSignatureOptionItem(
            labelPSPDFKITsignature,
            labelPSPDFKITsignature,
            [],
            true,
            !isFreelancer,
            false,
            [],
            []
          ),
        ],
      });
    }

    this.setState({
      isEditModalVisiblePSPDFKITsigntaure: false,
      selectedCategory: "",
      modalErrorMessage: "",
      labelPSPDFKITsignature: "",
      binddynamicSelectsignautre: "",
      freelancerToggleChecked: false,
      organizationToggledChecked: false,
      selectedOrganizationType: "",
      selectedExternalOrg: "",
      externalOrgToggledChecked: false,
      selectedExternalApproval: "",
      externalApprovalToggledChecked: false,
    });

    this.child.current.signatureTextBox(labelPSPDFKITsignature);
  }

  modifySignatureFieldWithValues = (label, uniqueId, formUniqueId) => {
    let { optionsSignature } = this.state;
    let currentItemIndex = optionsSignature.findIndex(
      (val) => val.label === label
    );

    if (currentItemIndex !== -1) {
      if (!optionsSignature[currentItemIndex].items) {
        optionsSignature[currentItemIndex].items = [];
      }

      if (!optionsSignature[currentItemIndex].relatedIds) {
        optionsSignature[currentItemIndex].relatedIds = [];
      }

      optionsSignature[currentItemIndex].names.push(uniqueId);
      optionsSignature[currentItemIndex].relatedIds.push({
        widgetId: uniqueId,
        signatureId: formUniqueId,
      });

      this.setState({ optionsSignature });
    }
  };

  modifyTextFieldWithValues = (label, uniqueName) => {
    let { options } = this.state;
    let currentItemIndex = options.findIndex((val) => val.label === label);

    if (currentItemIndex !== -1) {
      options[currentItemIndex].names.push(uniqueName);
      this.setState({ options });
    }
  };

  addDate = () => {
    // let currDate = moment(new Date().getTime()).format("DD/MM/YYYY");
    // this.onAddTextField(currDate);
    this.setDynamicFields("תאריך מילוי המסמך");
    this.child.current.currentDateTextBox();
  };

  closeEditTextModal = () => {
    this.setState({ isEditModalVisible: false, isPopupShouldToggle: false });
    this.savePDF(true);
  };

  closeEditTextModalFreelancer = async () => {
    //Check If All Field are filled out OR NOT
    let { options, optionsSignature, certificateCategory, file } = this.state;

    this.setState({ freelancerModalErrorMessage: "" });

    if (options.filter((item) => item.value.trim().length === 0).length > 0) {
      this.setState({
        freelancerModalErrorMessage: "יש לוודא שכל השדות מלאים",
      });
      this.freelancerModalRef.current.setLoadingFalse();
    } else if (
      optionsSignature.filter((item) => item.isFilled === false).length > 0
    ) {
      this.setState({
        freelancerModalErrorMessage: "יש לחתום בכל השדות המופיעים כאן",
      });
      this.freelancerModalRef.current.setLoadingFalse();
    } else if (
      certificateCategory &&
      certificateCategory.length > 0 &&
      file === null
    ) {
      this.setState({ freelancerModalErrorMessage: "חובה להעלות תעודה" });
      this.freelancerModalRef.current.setLoadingFalse();
    } else {
      this.setState({
        isEditModalVisibleFreelancer: false,
        isPopupShouldToggle: false,
        isApproveButtonHidden: false,
      });

      await this.savePDFObjectsInDocument();
      this.setState({
        freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SEND,
      });
      this.freelancerModalRef.current.resetForm();
    }
  };

  onChangeDynamictext = (event, originalLabel = "") => {
    let { name, value } = event.target;

    if (name === "תאריך מילוי המסמך" || name.includes("תאריך")) {
      let split = value.split("-");
      let year = split[0];
      let month = split[1];
      let date = split[2];

      let combinevalue = date + "/" + month + "/" + year;
      value = combinevalue;
    }

    let optiondata = this.state.options;

    let freelancerOriginalLabels =
      this.buildOriginalsLabelForCurrentFreelancer(name);

    for (let i = 0; i < optiondata.length; i++) {
      if (originalLabel.length > 0) {
        if (optiondata[i].originalLabel === originalLabel) {
          optiondata[i].value = value;
          this.child.current.getFormFields(value, originalLabel);
        }
      } else {
        if (optiondata[i].label === name) {
          for (let j = 0; j < freelancerOriginalLabels.length; j++) {
            if (freelancerOriginalLabels[j] === optiondata[i].originalLabel) {
              optiondata[i].value = value;
              this.child.current.getFormFields(
                value,
                freelancerOriginalLabels[j]
              );
            }
          }
        }
      }
    }

    this.setState({ options: optiondata });
  };

  buildOriginalsLabelForCurrentFreelancer = (label) => {
    let arr = [];

    try {
      let { userInfoObj } = this.state;

      if (userInfoObj && userInfoObj.project) {
        let { project, freelancer } = userInfoObj;

        let categories = [];

        if (project.freelancers) {
          project.freelancers
            .filter(
              (item) =>
                item.email.toLowerCase() === freelancer.email.toLowerCase()
            )
            .map((item) => {
              item.category.map((c) => {
                if (!categories.includes(c)) {
                  categories.push(c);
                }

                return null;
              });

              return null;
            });

          categories.map((val) => {
            arr.push(label + "_" + val);
            return null;
          });
        }
      }
    } catch (e) {
      console.log(e);
    }

    return arr;
  };

  handleChange(event) {
    this.setState({ dynamicSelect: event.target.value });
  }

  bindhandleChange(event) {
    let value = event.target.value;

    this.setState({
      binddynamicSelect: value,
      fieldNamePSPDFKIT: value,
      labelPSPDFKIT: value,
      freelancerToggleChecked: FREELANCERS_FIELDS.includes(value),
      organizationToggledChecked: ORGANIZATIONS_FIELDS.includes(value),
    });
  }

  categoryChange(event) {
    this.setState({ selectedCategory: event.target.value });
  }

  onOrganizationTypeChange(event) {
    this.setState({ selectedOrganizationType: event.target.value });
  }

  onExternalOrgChange(event) {
    this.setState({ selectedExternalOrg: event.target.value });
  }

  onExternalApprovalChange(event) {
    this.setState({ selectedExternalApproval: event.target.value });
  }

  handleChangesignature(event) {
    this.setState({ dynamicSelectsignautre: event.target.value });
    this.child.current.setState({
      dynamicSelectsignautrechild: event.target.value,
    });
  }

  bindhandleChangesignature(event) {
    let value = event.target.value;

    this.setState({
      binddynamicSelectsignautre: value,
      fieldNamePSPDFKITsignature: value,
      labelPSPDFKITsignature: value,
      freelancerToggleChecked: FREELANCERS_FIELDS.includes(value),
      organizationToggledChecked: ORGANIZATIONS_FIELDS.includes(value),
      externalOrgToggledChecked: EXTERNAL_ORGS_SIGNATURE_FIELDS.includes(value),
      externalApprovalToggledChecked:
        EXTERNAL_APPROVALS_SIGNATURE_FIELDS.includes(value),
    });
  }

  closeEditTextModalPSPDFKITcross() {
    this.setState({
      isEditModalVisiblePSPDFKIT: false,
    });
  }

  closeEditTextModalPSPDFKIT() {
    let {
      fieldNamePSPDFKIT,
      options,
      labelPSPDFKIT,
      selectedCategory,
      freelancerToggleChecked,
      organizationToggledChecked,
      selectedOrganizationType,
      externalOrgToggledChecked,
      selectedExternalOrg,
      externalApprovalToggledChecked,
      selectedExternalApproval,
    } = this.state;
    this.setState({ modalErrorMessage: "" });

    let canPush = true;
    let isFreelancer = false;

    if (
      FREELANCERS_FIELDS.includes(fieldNamePSPDFKIT) &&
      !FREELANCER_AND_ORG_FIELDS.includes(fieldNamePSPDFKIT) &&
      !organizationToggledChecked
    ) {
      isFreelancer = true;
    }

    if (!fieldNamePSPDFKIT) canPush = false;

    if (
      !fieldNamePSPDFKIT ||
      (fieldNamePSPDFKIT && fieldNamePSPDFKIT.trim().length === 0) ||
      (isFreelancer && selectedCategory.length === 0) ||
      (freelancerToggleChecked && selectedCategory.length === 0) ||
      (organizationToggledChecked && selectedOrganizationType.length === 0) ||
      (externalOrgToggledChecked && selectedExternalOrg.length === 0) ||
      (externalApprovalToggledChecked && selectedCategory.length === 0)
    ) {
      this.setState({ modalErrorMessage: "יש למלא את כל השדות" });
      this.showNotificationAlert(
        ALERT_MODAL_TYPES.ERROR,
        "מילוי שדות",
        "יש למלא את כל השדות"
      );
      return;
    }

    if (isFreelancer || freelancerToggleChecked) {
      fieldNamePSPDFKIT = fieldNamePSPDFKIT + "_" + selectedCategory;
      labelPSPDFKIT = labelPSPDFKIT + "_" + selectedCategory;
    }

    if (organizationToggledChecked) {
      fieldNamePSPDFKIT = fieldNamePSPDFKIT + "+" + selectedOrganizationType;
      labelPSPDFKIT = labelPSPDFKIT + "+" + selectedOrganizationType;
    }

    if (externalOrgToggledChecked) {
      fieldNamePSPDFKIT = fieldNamePSPDFKIT + "@" + selectedExternalOrg;
      labelPSPDFKIT = labelPSPDFKIT + "@" + selectedExternalOrg;
    }

    if (externalApprovalToggledChecked) {
      fieldNamePSPDFKIT = fieldNamePSPDFKIT + "<" + selectedExternalApproval;
      labelPSPDFKIT = labelPSPDFKIT + "<" + selectedExternalApproval;
    }

    for (let i = 0; i < options.length; i++) {
      if (options[i].label === fieldNamePSPDFKIT) {
        canPush = false;
        break;
      }
    }

    if (canPush) {
      let isVisibleInDDL = false;

      if (!isFreelancer) {
        isVisibleInDDL = true;
      }

      this.setState({
        options: [
          ...options,
          this.buildOptionItem(
            fieldNamePSPDFKIT,
            fieldNamePSPDFKIT,
            "",
            [],
            true,
            isVisibleInDDL
          ),
        ],
      });
    }

    this.setState({
      isEditModalVisiblePSPDFKIT: false,
      selectedCategory: "",
      fieldNamePSPDFKIT: "",
      labelPSPDFKIT: "",
      binddynamicSelect: "",
      modalErrorMessage: "",
      freelancerToggleChecked: false,
      organizationToggledChecked: false,
      selectedOrganizationType: "",
      externalOrgToggledChecked: false,
      selectedExternalOrg: "",
      externalApprovalToggledChecked: false,
      selectedExternalApproval: "",
    });

    this.child.current.exmptyTextBox(
      fieldNamePSPDFKIT,
      labelPSPDFKIT,
      this.childtoggle.current.state.checked
    );
  }

  openDeleteDialog = (arrName, index) => {
    if (index === -1) {
      return;
    }

    confirmAlert({
      title: "מחיקת פריט מהמסמך",
      message: "האם אתה בטוח?",
      buttons: [
        {
          label: "לא, סגור",
          onClick: () => {},
        },
        {
          label: "כן",
          onClick: () => {
            let arr = this.state[arrName];

            if (arr && index < arr.length) {
              arr.splice(index, 1);

              this.setState({ [arrName]: arr });
            }
          },
        },
      ],
    });
  };

  uploadPDFFileToServerAndGetURL = async (file) => {
    let baseUrl = "";
    let fileName = "";

    try {
      if (file) {
        let { name } = file;

        name = "file_" + new Date().getTime() + "_.pdf";

        let formData = new FormData();
        formData.append("data", file, name);

        let url = ENV.API_URL + "/" + ENV.UPLOADS_API_URL;
        let res = await UploadImage(url, formData);

        if (res && res.success) {
          baseUrl = res.url;
          fileName = name;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      return { baseUrl, fileName };
    }
  };

  getCurrentDocumentFreelancerCategory() {
    let { userInfoObj } = this.state;
    let project = userInfoObj.project;
    let freelancer = userInfoObj.freelancer || {};
    let categories = [];

    if (project && project.freelancers && freelancer && freelancer.email) {
      project.freelancers
        .filter(
          (item) => item.email.toLowerCase() === freelancer.email.toLowerCase()
        )
        .map((item) => {
          item.category.map((c) => {
            if (!categories.includes(c)) {
              categories.push(c);
            }

            return null;
          });

          return null;
        });
    }

    return categories;
  }

  uploadFileToServer = async (
    file,
    isSigned = false,
    template = [],
    isLockFields = true,
    isAllFreelancerFieldsFilled = false,
    isOrg = false,
    isExternalOrg = false,
    isExternalApproval = false
  ) => {
    let { userInfoObj } = this.state;

    let bodyObj = {
      app: userInfoObj.app,
      docId: userInfoObj.docId,
      tokenId: userInfoObj.tokenId,
      userId: userInfoObj.user ? userInfoObj.user._id : null,
      tmc: userInfoObj.tmc,
      isSigned,
      template,
      email: userInfoObj.email,
      yzm: userInfoObj.yzm,
      isLockFields,
      categories: this.getCurrentDocumentFreelancerCategory(),
      isAllFreelancerFieldsFilled,
      isOrg,
      organizationType: userInfoObj.organizationType || "",
      category: userInfoObj.category || "",
      isExternalOrg,
      instanceTextFields: await this.getTextFieldsFromPDFInstance(),
      isExternalApproval,
    };

    let url = `${ENV.API_URL}/${ENV.DOCUMENTS_API_URL}/upt-file`;

    let formData = new FormData();

    formData.append("data", file, `file_${new Date().getTime()}.pdf`);
    formData.append("userInfo", JSON.stringify(bodyObj));

    let res = await UploadImage(url, formData);

    let success = res.success;
    let message = success ? "" : res.message;
    let uploadUrl = success ? res.url : "";

    return {
      success,
      message,
      uploadUrl,
    };
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  saveSignature = () => {
    let url = this.sigCanvas.getTrimmedCanvas().toDataURL("image/png");

    let { images } = this.state;

    if (url && url.length > 0) {
      images.push(url);
      this.setState({ images });
    }

    this.resetSignature();
  };

  resetSignature = () => {
    this.sigCanvas.clear();
    this.closSignatureModal();
  };

  fillNotesVisibleArray = (len) => {
    let notesVisibleArray = [];

    for (let i = 0; i < len; i++) {
      notesVisibleArray.push(true);
    }

    this.setState({ notesVisibleArray });
  };

  getPDFPageDimensions = () => {
    let pageWidth = 0,
      pageHeight = 0;

    try {
      pageWidth = 595.56; // old one :  this.pageRef.current.offsetWidth;
      pageHeight = 842.52; // old one : this.pageRef.current.offsetHeight;

      // you should get theses values from PDFPage file not from here to get the correct with and height
      //here i hardcoded it
    } catch (e) {}

    console.log(pageHeight, pageWidth);

    return {
      w: pageWidth,
      h: pageHeight,
    };
  };

  getPDFCenterDimensions = () => {
    let { w, h } = this.getPDFPageDimensions();

    return {
      w: w === 0 ? 0 : w / 2,
      h: h === 0 ? 0 : h / 2,
    };
  };

  uploadImageToCanvas = async (url, file) => {
    try {
      const img = await readAsImage(url);
      const id = genID();
      const { width, height } = img;

      let x = this.getPDFCenterDimensions().w;
      let y = this.getPDFCenterDimensions().h;

      if (x !== 0) {
        x = x - width / 2;

        if (x <= 0) {
          x = 0;
        }
      }

      if (y !== 0) {
        y = y - height / 2;
      }

      const object = {
          id,
          type: "image",
          width,
          height,
          x,
          y,
          payload: img,
          file,
        },
        allObjs = this.state.allObjects.map((objects, pIndex) =>
          pIndex === this.state.selectedPageIndex
            ? [...objects, object]
            : objects
        );

      this.setState({ allObjects: allObjs });
    } catch (e) {}
  };

  readImageFromUrlAndCreateFile = async (src) => {
    let index = src.lastIndexOf("/");
    let name = index !== -1 ? src.substr(index) : "";

    let url = ENV.API_URL + "/" + ENV.UPLOADS_API_URL + "/download" + name;
    this.child.current.addImageAnnotation(url);
    let blob = await fetch(url).then((r) => r.blob());

    this.uploadImageToCanvas(src, blob);
  };

  addImage = async (file) => {
    try {
      // get dataURL to prevent canvas from tainted
      const url = await readAsDataURL(file);
      this.child.current.addImageAnnotation(url);

      this.uploadImageToCanvas(url, file);
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  };

  onAddTextField = (text = "תיבת טקסט חדשה") => {
    if (this.state.selectedPageIndex >= 0) {
      this.addTextField(text);
    }
  };

  addTextField = (text) => {
    const id = genID();
    fetchFont(this.state.currentFont);

    const object = {
        id,
        text,
        type: "text",
        size: 25,
        width: 0, // recalculate after editing
        lineHeight: 1.4,
        fontFamily: this.state.currentFont,
        fontWeight: this.state.currWeight,
        color: this.state.color,
        x: this.getPDFCenterDimensions().w,
        y: this.getPDFCenterDimensions().h,
      },
      allObjs = this.state.allObjects.map((objects, pIndex) =>
        pIndex === this.state.selectedPageIndex ? [...objects, object] : objects
      );
    this.setState({
      allObjects: allObjs,
      color: "#000",
      currWeight: "100",
      currentFont: "Times-Roman",
    });
  };

  submitValues(isFreelancer = false) {
    let { options, optionsSignature } = this.state;

    options = options.filter((val) => val.isPresent);

    if (options.length === 0 && optionsSignature.length === 0) {
      if (isFreelancer) {
        this.savePDFObjectsInDocument();
        this.setState({
          freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SEND,
        });
      } else {
        this.savePDF(true);
      }

      this.setState({ isApproveButtonHidden: false });
    } else {
      let isFreelancerUser = this.getIsFreelancer();
      let isOrg = this.getIsOrg();
      let isExternalOrg = this.getIsExternalOrg();
      let isExternalApproval = this.getIsExternalApproval();

      let isCanToggle =
        isFreelancerUser || isOrg || isExternalOrg || isExternalApproval;

      this.setState({
        options,
        isEditModalVisible: isCanToggle ? false : true,
        isEditModalVisibleFreelancer: isCanToggle ? true : false,
        isPopupShouldToggle: true,
      });
    }
  }

  getValueByLabel = (label, freelancer) => {
    switch (label) {
      case FREELANCERS_FIELDS_TITLES.FIRST_NAME:
        return freelancer.firstName;
      case FREELANCERS_FIELDS_TITLES.LAST_NAME:
        return freelancer.lastName;
      case FREELANCERS_FIELDS_TITLES.FULL_NAME:
        return freelancer.firstName + " " + freelancer.lastName;
      case FREELANCERS_FIELDS_TITLES.EMAIL:
        return freelancer.email;
      case FREELANCERS_FIELDS_TITLES.PHONE:
        return freelancer.phone;
      case FREELANCERS_FIELDS_TITLES.SIGNATURE:
        return freelancer.userSettings && freelancer.userSettings.signatureUrl
          ? freelancer.userSettings.signatureUrl
          : "";
      case FREELANCERS_FIELDS_TITLES.STAMP:
        return freelancer.userSettings && freelancer.userSettings.stampUrl
          ? freelancer.userSettings.stampUrl
          : "";
      case FREELANCERS_FIELDS_TITLES.ADDRESS:
        return freelancer.userSettings && freelancer.userSettings.address
          ? freelancer.userSettings.address
          : "";
      case FREELANCERS_FIELDS_TITLES.ID_NUMBER:
        return freelancer.businessInformation &&
          freelancer.businessInformation.idNumber
          ? freelancer.businessInformation.idNumber
          : "";
      case FREELANCERS_FIELDS_TITLES.LICENSE_NUMBER:
        return freelancer.businessInformation &&
          freelancer.businessInformation.licenseNumber
          ? freelancer.businessInformation.licenseNumber
          : "";
      case FREELANCERS_FIELDS_TITLES.DATE_OF_SIGNATURE:
        return moment(new Date().getTime()).format("DD/MM/yyyy");

      default:
        return "";
    }
  };

  downloadPDF = () => {
    this.child.current.downLoadPDF();
  };

  removeItemFromTemplate = (formFieldName) => {
    try {
      let { userInfoObj } = this.state;
      let { template } = userInfoObj.document;
      template = template.filter(
        (item) => item.formFieldName !== formFieldName
      );

      userInfoObj.document.template = template;
      this.setState({ userInfoObj });
    } catch (e) {
      console.log(e);
    }
  };

  updateTemplateSignatureItemByLabel = (label, newId) => {
    try {
      let { userInfoObj } = this.state;
      let { template } = userInfoObj.document;

      let templateItemIndex = template.findIndex(
        (item) => item.label === label
      );

      if (templateItemIndex !== -1) {
        template[templateItemIndex].relatedIds[0].signatureId = newId;
        userInfoObj.document.template = template;
        this.setState({ userInfoObj });
      }
    } catch (e) {
      console.log(e);
    }
  };

  savePDF = async (
    isSigned = false,
    withSavingIndicators = true,
    isNavigateAwayAfterSaving = true
  ) => {
    this.prepareSavingIndicationIfItsOn(withSavingIndicators);

    let save = await this.savePDFObjectsInDocument();

    if (save !== null) {
      await this.uploadToServerAndNotifyUser(
        save,
        isSigned,
        withSavingIndicators,
        isNavigateAwayAfterSaving
      );
    }
  };

  prepareSavingIndicationIfItsOn = (withSavingIndicators = true) => {
    if (withSavingIndicators) {
      this.setState({
        isSavingPDF: true,
        isSavingFinished: false,
        savingResult: "",
      });
    }
  };

  findTemplateItemInPDFAnnotations = (arr, item) => {
    arr = arr.filter(
      (curr) => curr.formFieldName && curr.formFieldName.includes(item.label)
    );

    if (arr.length > 0) return arr[0].formFieldName;

    return item.formFieldName;
  };

  savePDFObjectsInDocument = async (isLockAllField = false) => {
    try {
      let { options, optionsSignature, userInfoObj } = this.state;
      let template = [];

      if (
        userInfoObj &&
        userInfoObj.document &&
        userInfoObj.document.template &&
        userInfoObj.document.template.length > 0
      ) {
        template = userInfoObj.document.template;
      }

      let pdfAnnotaionArrayList =
        await this.child.current.getAnnotationsArrayList();
      template = template.map((item) => {
        if (item.type === TEMPLATE_FIELDS_TYPES.TEXT) {
          let formFieldName = this.findTemplateItemInPDFAnnotations(
            pdfAnnotaionArrayList,
            item
          );
          item.formFieldName = formFieldName;
        }

        return item;
      });

      //Update Widget with Freelancer fields If it's Freelancer

      let isFreelancerUser = this.getIsFreelancer();

      if (isFreelancerUser) {
        await Promise.all(
          options.map(async (val) => {
            if (
              FREELANCERS_FIELDS.includes(val.label) ||
              val.originalLabel.lastIndexOf("_") !== -1
            ) {
              let { originalLabel, value } = val;

              for (let i = 0; i < template.length; i++) {
                if (template[i].label === originalLabel) {
                  let name = template[i].formFieldName;
                  let updatedValue =
                    value && value.length > 0 ? value : template[i].value;

                  template[i].value = updatedValue;

                  await this.child.current.getFormFields(updatedValue, name);
                }
              }
            }

            return null;
          })
        );

        optionsSignature.map((val) => {
          if (FREELANCERS_FIELDS.includes(val.label)) {
            let { originalLabel, isFilled } = val;
            let currentIndex = template.findIndex(
              (item) => item.label === originalLabel
            );

            if (currentIndex !== -1) {
              template[currentIndex].isFilled = isFilled;
            }
          }

          return null;
        });
      }

      let pdfBlob = await this.child.current.savePDFPSPDFKit(isLockAllField);

      //GET ALL FORM FIELDS & SIGNATURES WITH THEIR DETAILS
      let formFields = await this.child.current.getAnnotationsArrayList();

      //FILTER THE FIELDS WITH FIELDS AND SIGNATURES
      formFields.map((val) => {
        let templateIndex = template.findIndex(
          (item) => val.formFieldName === item.formFieldName
        );

        for (let i = 0; i < options.length; i++) {
          if (
            options[i].names.includes(val.formFieldName) &&
            templateIndex === -1 &&
            val.formFieldName !== undefined
          ) {
            template.push({
              ...val,
              label: options[i].label,
              originalLabel: options[i].originalLabel,
              isVisibleInDDL: options[i].isVisibleInDDL || true,
              isFocusField: options[i].isFocusField,
            });
          }
        }

        for (let i = 0; i < optionsSignature.length; i++) {
          if (
            optionsSignature[i].names.includes(val.id) &&
            templateIndex === -1
          ) {
            let index = template.findIndex(
              (item) =>
                item.label === optionsSignature[i].label &&
                item.type === TEMPLATE_FIELDS_TYPES.SIGNATURE
            );

            if (index === -1) {
              template.push({
                ...val,
                label: optionsSignature[i].label,
                originalLabel: optionsSignature[i].originalLabel,
                isFilled: optionsSignature[i].isFilled,
                isVisibleInDDL: optionsSignature[i].isVisibleInDDL || true,
                names: optionsSignature[i].names,
                items: [{ ...val }],
                relatedIds: optionsSignature[i].relatedIds,
              });
            } else {
              template[index].items.push({
                ...val,
              });
            }
          }
        }
        return null;
      });

      return {
        template,
        pdfBlob,
      };
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  uploadToServerAndNotifyUser = async (
    savePDFResult,
    isSigned = false,
    withSavingIndicators = true,
    isNavigateAwayAfterSaving = true,
    isLockFields = true,
    isAllFreelancerFieldsFilled = false,
    isOrg = false,
    isExternalOrg = false,
    isExternalApproval = false
  ) => {
    let { template, pdfBlob } = savePDFResult;
    let { success, message } = await this.uploadFileToServer(
      pdfBlob,
      isSigned,
      template,
      isLockFields,
      isAllFreelancerFieldsFilled,
      isOrg,
      isExternalOrg,
      isExternalApproval
    );
    //let success = true, message = "";

    if (withSavingIndicators) {
      let msg = success
        ? isSigned
          ? "המסמך נחתם בהצלחה"
          : "המסמך נשמר בהצלחה"
        : message;
      this.setState({
        savingResult: msg,
        isSavingFinished: true,
        savingResultSuccess: success,
      });
    }

    if (success) {
      this.child.current.setState({ flattenAnnotations: false });
    }

    setTimeout(() => {
      this.setState({ isSavingPDF: false });

      //Redirect user to the screen he came from
      if (isNavigateAwayAfterSaving) {
        this.redirectUserAfterSuccess();
      }
    }, 2200);
  };

  redirectUserAfterSuccess = () => {
    try {
      let { userInfoObj } = this.state;
      if (userInfoObj && userInfoObj.app) {
        let { app, tmc = false } = userInfoObj;

        if (tmc) {
          window.open("about:blank", "_self");
          window.close();
        } else {
          let url = userInfoObj.returnUrl || "";

          if (!url) {
            if (app === APP_OPTIONS.TOFES4) {
              url = ENV.TOFES4_URL;
            } else {
              url = ENV.ESIGN_URL;
            }
          }

          window.location.href = url;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  binddynamicSignautre(e, option) {
    let { originalLabel, isFilled = false } = option;
    let label =
      originalLabel !== null && originalLabel !== undefined
        ? originalLabel
        : JSON.stringify(e.target.value);

    localStorage.setItem("dynamicsignatureField", label);
    localStorage.setItem("dynamicsignatureField_isFilled", "" + isFilled);
    localStorage.setItem("dynamicsignatureField_originalLabel", originalLabel);

    this.child.current.openSignaturePopUp();
    this.setState({
      isEditModalVisible: false,
      isEditModalVisibleFreelancer: false,
      isPopupShouldToggle: true,
    });
  }

  toggleFillPopup = (fieldName) => {
    let { optionsSignature, isPopupShouldToggle } = this.state;

    for (let i = 0; i < optionsSignature.length; i++) {
      if (optionsSignature[i].originalLabel === fieldName) {
        optionsSignature[i].isFilled = true;
      }
    }

    let isFreelancerUser = this.getIsFreelancer();
    let isOrg = this.getIsOrg();
    let isExternalOrg = this.getIsExternalOrg();
    let isExternalApproval = this.getIsExternalApproval();

    let isCanToggle =
      isFreelancerUser || isOrg || isExternalOrg || isExternalApproval;

    if (isPopupShouldToggle) {
      this.setState({
        isEditModalVisible: isCanToggle ? false : true,
        isEditModalVisibleFreelancer: isCanToggle ? true : false,
        isPopupShouldToggle: false,
        isApproveButtonHidden: true,
      });
    }

    this.setState({
      optionsSignature,
    });
  };

  viewApproveButtonAgain = () => {
    this.setState({ isApproveButtonHidden: false });
  };

  freelancerToggledCheckChanged = (e) => {
    let freelancerToggleChecked = !this.state.freelancerToggleChecked;

    this.resetAllToggles();
    this.setState({ freelancerToggleChecked });
  };

  organizationToggledCheckChanged = (e) => {
    let organizationToggledChecked = !this.state.organizationToggledChecked;

    this.resetAllToggles();
    this.setState({ organizationToggledChecked });
  };

  externalOrgToggledCheckChanged = (e) => {
    let externalOrgToggledChecked = !this.state.externalOrgToggledChecked;

    this.resetAllToggles();
    this.setState({ externalOrgToggledChecked });
  };

  externalApprovalToggledCheckChanged = (e) => {
    let externalApprovalToggledChecked =
      !this.state.externalApprovalToggledChecked;

    this.resetAllToggles();
    this.setState({ externalApprovalToggledChecked });
  };

  resetAllToggles = () => {
    this.setState({
      organizationToggledChecked: false,
      freelancerToggleChecked: false,
      externalOrgToggledChecked: false,
      externalApprovalToggledChecked: false,
    });
  };

  showNotificationAlert = (type, title, msg, timer = 0) => {
    switch (type) {
      case ALERT_MODAL_TYPES.ERROR:
        setTimeout(() => {
          NotificationManager.error(msg, title);
        }, timer);
        break;
      case ALERT_MODAL_TYPES.SUCCESS:
        setTimeout(() => {
          NotificationManager.success(msg, title);
        }, timer);
        break;
      case ALERT_MODAL_TYPES.INFO:
      default:
        setTimeout(() => {
          NotificationManager.info(msg, title);
        }, timer);
        break;
    }
  };

  getTextFieldsGroupByCategory = async () => {
    try {
      let arr = await this.child.current.getAnnotationsArrayList();

      arr = arr
        .filter(
          (item) =>
            item.formFieldName !== null && item.formFieldName !== undefined
        )
        .filter(
          (item) =>
            item.formFieldName.lastIndexOf("_") !== -1 ||
            item.formFieldName.lastIndexOf("+") !== -1
        )
        .map((item) => item.formFieldName);

      let jsonArr = [];

      arr.forEach((item) => {
        let labelWithCategory = item.substr(0, item.indexOf("-"));
        let seperator = labelWithCategory.includes("_") ? "_" : "+";
        let label = labelWithCategory.substr(0, item.indexOf(seperator));
        let category = labelWithCategory.substr(item.indexOf(seperator) + 1);

        let ind = jsonArr.findIndex(
          (it2) => it2.labelWithCategory === labelWithCategory
        );

        if (ind === -1) {
          jsonArr.push({
            label,
            category,
            labelWithCategory,
            formFields: [item],
          });
        } else {
          jsonArr[ind].formFields.push(item);
        }
      });

      return jsonArr;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  getFormFieldsFromArray = (arr) => {
    if (!arr) return [];

    arr = arr.filter((item) => {
      if (!item.formFieldName) return false;
      return (
        !item.formFieldName.includes("@") &&
        !item.formFieldName.includes("+") &&
        !item.formFieldName.includes("_")
      );
    });

    return arr.map((item) => item.formFieldName);
  };

  getTextFieldsFromPDFInstance = async () => {
    let data = await this.child.current.getFullAnnotationsList();
    return this.getFormFieldsFromArray(data);
  };

  printDifferenceBetweenInstanceItemsAndTemplateItems = async () => {
    try {
      let instanceItems = await this.getTextFieldsFromPDFInstance();
      let templateItems = this.getFormFieldsFromArray(
        this.state.userInfoObj.document.template
      );

      let exitInTemplateAndNOTInInstance = templateItems.filter(
        (item) => !instanceItems.includes(item)
      );
      let exitInInstanceAndNOTInTemplate = instanceItems.filter(
        (item) => !templateItems.includes(item)
      );

      console.log(
        "exitInTemplateAndNOTInInstance: ",
        exitInTemplateAndNOTInInstance
      );
      console.log(
        "exitInInstanceAndNOTInTemplate: ",
        exitInInstanceAndNOTInTemplate
      );
    } catch (e) {
      console.log(e);
    }
  };

  instanceInitialized = async (instance) => {
    this.setState({ isSaveAndExitCustomVisible: true });

    let isFreelancer = this.getIsFreelancer();

    if (isFreelancer) {
      this.fillFreelancerFields();

      if (
        this.state.freelancerSaveButtonStatus === FREELANCERS_SAVE_STATUS.SIGNED
      ) {
        this.child.current.removeDownloadFromToolBarIcons();
      }
    }
  };

  fillFreelancerFields = async () => {
    let { userInfoObj, options, optionsSignature } = this.state;
    let { freelancer } = userInfoObj;

    let template = userInfoObj?.document?.template || [];

    let instanceTextFields = await this.getTextFieldsGroupByCategory();

    options = await Promise.all(
      options.map(async (val) => {
        //let ind = val.label.lastIndexOf("_");

        //if (ind !== -1 || FREELANCERS_FIELDS.includes(val.label)){
        if (
          (FREELANCERS_FIELDS.includes(val.label) ||
            val.originalLabel.lastIndexOf("_") !== -1) &&
          val.value.length === 0
        ) {
          let value = "";

          if (
            FREELANCERS_FIELDS.includes(val.label) &&
            val.label !== "תאריך ביקורת בנכס"
          ) {
            value = this.getValueByLabel(val.label, freelancer);

            for (let i = 0; i < template.length; i++) {
              if (template[i].label === val.originalLabel) {
                let arrItem = instanceTextFields.find(
                  (it2) =>
                    it2.labelWithCategory === val.originalLabel &&
                    it2.formFields.length > 1
                );

                if (arrItem) {
                  const newVal = value;

                  arrItem.formFields.forEach(async (formField) => {
                    await this.child.current.getFormFields(newVal, formField);
                  });
                } else {
                  await this.child.current.getFormFields(
                    value,
                    template[i].formFieldName
                  );
                }
              }
            }
          } else {
            let templateItem = template.find(
              (it) => it.label === val.originalLabel
            );

            if (templateItem) {
              value = templateItem.value || "";

              await this.child.current.getFormFields(
                value,
                templateItem.formFieldName
              );
            }
          }

          return {
            ...val,
            value,
          };
        } else {
          return {
            ...val,
          };
        }
      })
    );

    optionsSignature = optionsSignature.map((val) => {
      if (
        FREELANCERS_FIELDS.includes(val.label) &&
        val.isFilled === false &&
        this.getValueByLabel(val.label, freelancer).length > 0
      ) {
        return {
          ...val,
          isFilled: true,
        };
      } else {
        return {
          ...val,
        };
      }
    });

    this.setState({ options, optionsSignature });
  };

  declinedSignature = async (declineReason) => {
    this.setState({ declineReasonErrorMessage: "" });

    if (declineReason.trim().length === 0) {
      this.setState({ declineReasonErrorMessage: "חובה להזין סיבת דחייה" });
    } else {
      this.setState({
        isEditModalVisibleFreelancer: false,
        isApproveButtonHidden: false,
      });
      this.freelancerModalRef.current.resetForm();

      try {
        let url = `${ENV.API_URL}/${ENV.DOCUMENTS_API_URL}/freelancer-decline-signature`;

        let params = {
          reason: declineReason,
          documentId: this.state.userInfoObj?.document?._id,
          email: this.state.userInfoObj?.freelancer?.email,
          categories: this.getFreelancerCategories(),
        };

        await HttpRequest(url, params, HttpRequestTypes.POST, null);

        this.redirectUserAfterSuccess();
      } catch (e) {
        console.log(e);
      }
    }
  };

  getFreelancerCategories = () => {
    try {
      let categories = [];
      let currentDocumentCategories = [];
      let { userInfoObj } = this.state;
      let freelancer = userInfoObj.freelancer;
      let template = userInfoObj.document.template;

      if (
        freelancer.businessInformation &&
        freelancer.businessInformation.categories
      ) {
        categories = freelancer.businessInformation.categories;
      } else if (freelancer.categories) {
        categories = freelancer.categories;
      }

      template
        .filter((val) => {
          let lastIndexOfUnderscore = val.label.lastIndexOf("_");

          return (
            lastIndexOfUnderscore !== -1 &&
            categories.includes(val.label.substr(lastIndexOfUnderscore + 1))
          );
        })
        .map((val) => {
          let cat = val.label.substr(val.label.lastIndexOf("_") + 1);

          if (!currentDocumentCategories.includes(cat)) {
            currentDocumentCategories.push(cat);
          }

          return null;
        });

      return currentDocumentCategories;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  freelancersSaveButton = async () => {
    let { freelancerSaveButtonStatus } = this.state;

    if (freelancerSaveButtonStatus === FREELANCERS_SAVE_STATUS.SAVE) {
      this.setState({ isApproveButtonHidden: true });
      this.submitValues(true);
    } else if (freelancerSaveButtonStatus === FREELANCERS_SAVE_STATUS.SEND) {
      //Export PDF
      this.prepareSavingIndicationIfItsOn(true);

      setTimeout(async () => {
        let isToLockAllFields = false;
        let isAllFreelancerFieldsFilled =
          this.getIsAllFreelancersFieldsFilled();

        if (this.getIsFreelancer()) {
          let isContainOrganizationFields =
            this.getIsContainOrganizationFields();

          if (isAllFreelancerFieldsFilled && !isContainOrganizationFields) {
            isToLockAllFields = true;
          }
        }

        let save = await this.savePDFObjectsInDocument(isToLockAllFields);

        if (save !== null) {
          await this.uploadToServerAndNotifyUser(
            save,
            true,
            true,
            true,
            isToLockAllFields,
            isAllFreelancerFieldsFilled,
            this.getIsOrg(),
            this.getIsExternalOrg(),
            this.getIsExternalApproval()
          );
          await this.uploadFreelancerCertificateAndUpdateProjectIfExist();
          this.setState({
            freelancerSaveButtonStatus: FREELANCERS_SAVE_STATUS.SIGNED,
          });
        }
      }, 700);
    }
  };

  uploadFreelancerCertificateAndUpdateProjectIfExist = async () => {
    try {
      let { file, userInfoObj } = this.state;

      if (!file) return;

      let formData = new FormData();
      formData.append("data", file);

      let params = {
        projectId: userInfoObj.project._id,
        category: userInfoObj.category,
        email: userInfoObj.email,
      };

      formData.append("jsonData", JSON.stringify(params));
      let url = `${ENV.API_URL}/api/project/upload-certificate`;

      await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // let res = await fetch(url, {
      //   headers: {
      //     "Content-Type": "multipart/form-data"
      //   },
      //   method: 'POST',
      //   body: formData
      // });

      // if (res) {
      //   let response = await res.json();
      //   console.log("response:: ", response);
      // }
    } catch (e) {
      console.log(e);
    }
  };

  getIsContainOrganizationFields = () => {
    try {
      let { userInfoObj } = this.state;

      if (
        userInfoObj &&
        userInfoObj.document &&
        userInfoObj.document.template
      ) {
        let { template } = userInfoObj.document;

        let organizationFields = template.filter((val) => {
          let lastIndexOfUnderscore = val.label.lastIndexOf("+");
          return lastIndexOfUnderscore !== -1;
        });

        return organizationFields.length > 0;
      }
    } catch (e) {
      console.log(e);
    }

    return false;
  };

  getFreelancerSaveButtonTextByStatus = () => {
    let { freelancerSaveButtonStatus } = this.state;

    switch (freelancerSaveButtonStatus) {
      case FREELANCERS_SAVE_STATUS.SEND:
        return "שליחה";

      case FREELANCERS_SAVE_STATUS.SAVE:
        return "אישור המסמך";

      case FREELANCERS_SAVE_STATUS.SIGNED:
      default:
        return "מסמך כבר חתום";
    }
  };

  certificateFileChanged = (event) => {
    let selectedFile = event.target.files[0];

    this.setState({ file: selectedFile });
  };

  render() {
    return (
      <div className="vw-100 relative">
        <NotificationContainer />

        <AddSignatureModal
          isVisible={this.state.isEditModalVisiblePSPDFKITsigntaure}
          closeModal={() =>
            this.setState({ isEditModalVisiblePSPDFKITsigntaure: false })
          }
          optionsSignature={this.state.optionsSignature.filter(
            (val) =>
              val.isVisibleInDDL === undefined || val.isVisibleInDDL === true
          )}
          binddynamicSelectsignautre={this.state.binddynamicSelectsignautre}
          bindhandleChangesignature={this.bindhandleChangesignature.bind(this)}
          labelPSPDFKITsignature={this.state.labelPSPDFKITsignature}
          setLabelPSPDFKITsignature={(val) =>
            this.setState({ labelPSPDFKITsignature: val })
          }
          addSignature={this.closeEditTextModalPSPDFKITsignature.bind(this)}
          categories={this.state.categories}
          selectedCategory={this.state.selectedCategory}
          onCategoryChange={this.categoryChange.bind(this)}
          errorMessage={this.state.modalErrorMessage}
          freelancerToggledCheckChanged={this.freelancerToggledCheckChanged.bind(
            this
          )}
          freelancerToggleChecked={this.state.freelancerToggleChecked}
          organizationToggledChecked={this.state.organizationToggledChecked}
          organizationToggledCheckChanged={this.organizationToggledCheckChanged.bind(
            this
          )}
          selectedOrganizationType={this.state.selectedOrganizationType}
          onOrganizationTypeChange={this.onOrganizationTypeChange.bind(this)}
          externalOrgToggledChecked={this.state.externalOrgToggledChecked}
          externalOrgToggledCheckChanged={this.externalOrgToggledCheckChanged.bind(
            this
          )}
          selectedExternalOrg={this.state.selectedExternalOrg}
          onExternalOrgChange={this.onExternalOrgChange.bind(this)}
          externalApprovalToggledChecked={
            this.state.externalApprovalToggledChecked
          }
          externalApprovalToggledCheckChanged={this.externalApprovalToggledCheckChanged.bind(
            this
          )}
          selectedExternalApproval={this.state.selectedExternalApproval}
          onExternalApprovalChange={this.onExternalApprovalChange.bind(this)}
        />

        <AddTextModal
          isVisible={this.state.isEditModalVisiblePSPDFKIT}
          closeModal={() =>
            this.setState({ isEditModalVisiblePSPDFKIT: false })
          }
          labelPSPDFKIT={this.state.labelPSPDFKIT}
          setLabelPSPDFKITAndFieldName={(val) =>
            this.setState({ labelPSPDFKIT: val, fieldNamePSPDFKIT: val })
          }
          fieldNamePSPDFKIT={this.state.fieldNamePSPDFKIT}
          binddynamicSelect={this.state.binddynamicSelect}
          bindhandleChange={this.bindhandleChange.bind(this)}
          options={this.state.options.filter(
            (val) =>
              val.isVisibleInDDL === undefined || val.isVisibleInDDL === true
          )}
          closeEditTextModalPSPDFKIT={this.closeEditTextModalPSPDFKIT.bind(
            this
          )}
          categories={this.state.categories}
          selectedCategory={this.state.selectedCategory}
          onCategoryChange={this.categoryChange.bind(this)}
          errorMessage={this.state.modalErrorMessage}
          freelancerToggledCheckChanged={this.freelancerToggledCheckChanged.bind(
            this
          )}
          freelancerToggleChecked={this.state.freelancerToggleChecked}
          organizationToggledChecked={this.state.organizationToggledChecked}
          organizationToggledCheckChanged={this.organizationToggledCheckChanged.bind(
            this
          )}
          selectedOrganizationType={this.state.selectedOrganizationType}
          onOrganizationTypeChange={this.onOrganizationTypeChange.bind(this)}
          externalOrgToggledChecked={this.state.externalOrgToggledChecked}
          externalOrgToggledCheckChanged={this.externalOrgToggledCheckChanged.bind(
            this
          )}
          selectedExternalOrg={this.state.selectedExternalOrg}
          onExternalOrgChange={this.onExternalOrgChange.bind(this)}
          externalApprovalToggledChecked={
            this.state.externalApprovalToggledChecked
          }
          externalApprovalToggledCheckChanged={this.externalApprovalToggledCheckChanged.bind(
            this
          )}
          selectedExternalApproval={this.state.selectedExternalApproval}
          onExternalApprovalChange={this.onExternalApprovalChange.bind(this)}
        />

        <FillPDFModal
          isVisible={this.state.isEditModalVisible}
          closeModal={() => this.setState({ isEditModalVisible: false })}
          options={this.state.options}
          optionsSignature={this.state.optionsSignature}
          onChangeDynamictext={this.onChangeDynamictext.bind(this)}
          binddynamicSignautre={this.binddynamicSignautre.bind(this)}
          closeEditTextModal={this.closeEditTextModal.bind(this)}
        />

        <FillPDFFReelancerModal
          isVisible={this.state.isEditModalVisibleFreelancer}
          closeModal={() =>
            this.setState({
              isEditModalVisibleFreelancer: false,
              isApproveButtonHidden: false,
            })
          }
          options={this.state.options}
          optionsSignature={this.state.optionsSignature}
          onChangeDynamictext={this.onChangeDynamictext.bind(this)}
          binddynamicSignautre={this.binddynamicSignautre.bind(this)}
          closeEditTextModal={this.closeEditTextModalFreelancer.bind(this)}
          isOrg={this.getIsOrg()}
          isExternalOrg={this.getIsExternalOrg()}
          isExternalApproval={this.getIsExternalApproval()}
          declinedSignature={this.declinedSignature.bind(this)}
          errorMessage={this.state.freelancerModalErrorMessage}
          declineReasonErrorMessage={this.state.declineReasonErrorMessage}
          ref={this.freelancerModalRef}
          freelancerCategories={this.state.currentDocumentFreelancerCategories}
          organizationCategories={this.state.organizationCategories}
          userInfoObj={this.state.userInfoObj}
          certificateCategory={this.state.certificateCategory}
          fileChanged={this.certificateFileChanged.bind(this)}
          file={this.state.file}
        />

        <LoadingModal
          isVisible={this.state.isSavingPDF}
          isSavingFinished={this.state.isSavingFinished}
          text={this.state.savingResult}
          savingResultSuccess={this.state.savingResultSuccess}
          getIsEsignUser={this.getIsEsignUser.bind(this)}
        />

        <div className="header--section">
          <DocumentHeader
            ref={this.childtoggle}
            isLoggedIn={this.state.isLoggedIn}
            pdfUrl={this.state.pdfUrl}
            savePDF={this.savePDF.bind(this)}
            submitValues={this.submitValues.bind(this)}
            link={this.state.link}
            userInfoObj={this.state.userInfoObj}
            userInfo={this.state.userInfoObj?.user}
            token={this.state.userInfoObj?.token}
            downloadPDF={this.downloadPDF.bind(this)}
            showClipboardCopyMessage={() =>
              this.showNotificationAlert(
                ALERT_MODAL_TYPES.SUCCESS,
                "העתקת קישור",
                "הקישור הועתק בהצלחה"
              )
            }
            getIsEsignUser={this.getIsEsignUser.bind(this)}
          />
        </div>

        <div className="body--section">
          <div
            className="scroll-down"
            onClick={() => (window.location.href = "#btnn")}
          ></div>
          <div className="pspdfkit--section">
            <div className="pspdfkit--container">
              {this.state.canMountPSPDFKIT && (
                <PSPDFKit
                  userInfo={this.state.userInfoObj?.user}
                  ref={this.child}
                  documentUrl={this.state.pdfUrl}
                  baseUrl={baseUrl}
                  toggleFillPopup={this.toggleFillPopup.bind(this)}
                  modifySignatureFieldWithValues={this.modifySignatureFieldWithValues.bind(
                    this
                  )}
                  modifyTextFieldWithValues={this.modifyTextFieldWithValues.bind(
                    this
                  )}
                  userInfoObj={this.state.userInfoObj}
                  instanceInitialized={this.instanceInitialized.bind(this)}
                  removeItemFromTemplate={this.removeItemFromTemplate.bind(
                    this
                  )}
                  updateTemplateSignatureItemByLabel={this.updateTemplateSignatureItemByLabel.bind(
                    this
                  )}
                  optionsSignature={this.state.optionsSignature}
                  viewApproveButtonAgain={this.viewApproveButtonAgain.bind(
                    this
                  )}
                />
              )}
            </div>
            <div className="confirm--btn-container">
              {(this.getIsFreelancer() ||
                this.getIsOrg() ||
                this.getIsExternalOrg() ||
                this.getIsExternalApproval()) &&
                this.state.isSaveAndExitCustomVisible &&
                !this.state.isApproveButtonHidden && (
                  <div className="btn-save-and-exit-container">
                    <button
                      className="btn blue-button btn-document-header btn-same-width"
                      id="btnn"
                      style={{
                        direction: "rtl",
                        color: "#fff",
                        borderColor: Colors.PRIMARY_BLUE,
                        backgroundColor: Colors.PRIMARY_BLUE,
                        order: 1,
                      }}
                      onClick={() => this.freelancersSaveButton()}
                    >
                      {this.getFreelancerSaveButtonTextByStatus()}
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="right--menu">
            <DocumentMenu
              isLoggedIn={this.state.isLoggedIn}
              addConstant={this.addConstant.bind(this)}
              signatureImage={this.signatureImage.bind(this)}
              stampImage={this.stampImage.bind(this)}
              addText={this.addText.bind(this)}
              addSignature={this.addSignature.bind(this)}
              addDate={this.addDate.bind(this)}
              userInfoObj={this.state.userInfoObj}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Documents;
